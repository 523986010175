<template>
  <v-navigation-drawer
    color="#5966A8"
    app
    dark
    clipped
    right
    width="350"
    v-model="isMenuSidebarOpen"
  >
    <div class="w-100 text-center logo-wrapper">
      <img src="/img/logo-full.svg" width="160" />
    </div>
    <v-list class="flex-1 px-0" nav dense>
      <template v-for="menuItem in menuItems">
        <v-list-item
          :to="{ name: menuItem.to }"
          :key="menuItem.to"
          @click="$store.dispatch('appStore/setMenuSidebarOpen', false)"
        >
          <v-list-item-action>
            <v-icon v-text="menuItem.icon"></v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import AccountService from '@/services/AccountService';

export default {
  name: 'MainSidebar',
  mixins: [AccountService],
  computed: {
    isMenuSidebarOpen: {
      get() {
        return this.appState.isMenuSidebarOpen;
      },
      set(newValue) {
        this.$store.dispatch('appStore/setMenuSidebarOpen', newValue);
      },
    },
    menuItems() {
      return [
        {
          icon: 'mdi-clipboard-text',
          title: 'Dashboard',
          to: 'dashboard-page',
        },
        {
          icon: 'mdi-chart-bell-curve',
          title: 'Sale Overview',
          to: 'sale-overview-page',
        },
        {
          icon: 'mdi-view-dashboard',
          title: 'Products',
          to: 'products-page',
        },
        // {
        //   icon: 'mdi-account-multiple',
        //   title: 'Buyer Profile',
        //   to: 'buyer-profile-page',
        // },
        // {
        //   icon: 'mdi-account-multiple',
        //   title: 'Visitor Profile',
        //   to: 'visitor-profile-page',
        // },
        // {
        //   icon: 'mdi-message-text',
        //   title: 'Channel Profile',
        //   to: 'channel-profile-page',
        // },
        // {
        //   icon: 'mdi-clipboard-text',
        //   title: 'Reports',
        //   to: 'reports-page',
        // },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-wrapper {
  padding: 60px;
}
::v-deep .v-list-item {
  margin-bottom: 16px !important;
  padding: 0 70px !important;

  .v-list-item__title {
    font-size: 18px !important;
    font-weight: 700 !important;
  }

  &::before {
    background-color: #29326b !important;
  }

  &:hover,
  &--active {
    border-radius: 0 !important;
    color: #fba220 !important;

    &::before {
      background-color: #29326b !important;
      opacity: 1 !important;
      z-index: -1 !important;
    }
  }
}
</style>
