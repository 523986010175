<template>
  <v-app-bar app fixed clipped-right dark height="65" color="#2A346F">
    <img src="/img/logo-mini.svg" height="30" />
    <v-spacer />
    <div class="fs-20 fw-800">WHIMCU - Customer Analytics</div>
    <v-spacer />
    <v-app-bar-nav-icon
      @click="
        $store.dispatch(
          'appStore/setMenuSidebarOpen',
          !appState.isMenuSidebarOpen,
        )
      "
    />
  </v-app-bar>
</template>

<script>
export default {
  name: 'MainHeader',
};
</script>

<style lang="scss" scoped>
</style>
