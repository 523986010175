import { render, staticRenderFns } from "./MainLayout.vue?vue&type=template&id=23c444b1&scoped=true&"
import script from "./MainLayout.vue?vue&type=script&lang=js&"
export * from "./MainLayout.vue?vue&type=script&lang=js&"
import style0 from "./MainLayout.vue?vue&type=style&index=0&id=23c444b1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23c444b1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VApp,VContainer,VMain})
