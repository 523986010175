import { render, staticRenderFns } from "./MainSidebar.vue?vue&type=template&id=574b869b&scoped=true&"
import script from "./MainSidebar.vue?vue&type=script&lang=js&"
export * from "./MainSidebar.vue?vue&type=script&lang=js&"
import style0 from "./MainSidebar.vue?vue&type=style&index=0&id=574b869b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "574b869b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VIcon,VList,VListItem,VListItemAction,VListItemTitle,VNavigationDrawer})
