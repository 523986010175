<template>
  <v-app>
    <v-main>
      <main-header />
      <v-container fluid class="main-container">
        <main-sidebar />
        <transition name="fade" mode="out-in" appear>
          <router-view :key="$route.fullPath"></router-view>
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import MainHeader from '@/components/Layouts/MainLayout/MainHeader.vue';
import MainSidebar from '@/components/Layouts/MainLayout/MainSidebar.vue';

export default {
  name: 'MainLayout',
  components: {
    MainHeader,
    MainSidebar,
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  padding: 50px;
}
</style>
